
var axios = require('axios');
import Vue from "vue";
import latlng from "latitude-longitude";
import store from "./store";
const {srcToWebP} = require('webp-converter-browser');
let source_link = store.state.serverAddress;

export default new class Airtable {
  constructor() {

    // Always set yourself back to 12 on every refresh
    // store.commit("setMeetsLoadedIndex", 12);
    store.commit("setMeetGroupsLoadedIndex", 0);
    this.offsetMeets = [];

    this.config = {
      headers: {
          "Authorization": `Bearer ${process.env.VUE_APP_KEY}`,
          "Content-Type": "application/json"
        }
    };
    // this.webpify(); EXECUTIVE FUNCTION RAN BY REB TO COMPRESS IMAGES
  }

  distance(coords) {
    if (!store.state.your_location) {
      return null;
    }
    return latlng.getDistance([store.state.your_location.latitude, store.state.your_location.longitude], [coords.lat, coords.lng]);
  }

  process_latlng(id, address) {
    return new Promise((coordsRes) => {
      let params = {
        id: id,
        address: address
      };
       axios.post(`${source_link}/process_latlng`, params).then((coordsResObj) => {
        coordsRes(coordsResObj.data);
       });
    })
    .catch((err) => {
      console.warn("Error: ", err + " " + "parsedString");
    });
  }
   

  submitEvent(obj) {
    axios.post(`${source_link}/submit_event`, obj);
  }

  uploadImageToField(image, id) {
    let params = {
      image: image,
      id: id
    }
    axios.post(`${source_link}/upload_image_to_field`, params)
  }

  // Fetch the rest of the website content from Airtable
  async init_airtable2() {
    return new Promise((resolved) => {
      axios.post(`${source_link}/connect_table2`).then((res) => {
        // French translation
        if (store.state.lang === "fr") {
          Vue.prototype.$header = res.data.records[0].fields.Header_fr;
          Vue.prototype.$smalltext = res.data.records[0].fields.Small_Text_fr;
          Vue.prototype.$CTA = res.data.records[0].fields.CTA_fr;
          Vue.prototype.$sort_CTA = res.data.records[0].fields.Sort_CTA_fr;

        // English translation
        } else {
          Vue.prototype.$header = res.data.records[0].fields.Header;
          Vue.prototype.$smalltext = res.data.records[0].fields.Small_Text;
          Vue.prototype.$CTA = res.data.records[0].fields.CTA;
          Vue.prototype.$sort_CTA = res.data.records[0].fields.Sort_CTA;
        }
        Vue.prototype.$og_image =  res.data.records[0].fields.og_image;
        Vue.prototype.$bg_image =  res.data.records[0].fields.BG_Pattern[0].url;
        
        resolved(res);
      })
    });
  }

  async init_shop() {
    return new Promise((resolved) => {
      axios.post(`${source_link}/init_shop`).then((res) => {
        Vue.prototype.$shop = res.data.records[1].fields;
        resolved(res);
      })
    });

  }

  async init_shop_items() {
    return new Promise((resolved) => {
      axios.post(`${source_link}/init_shop_items`).then((res) => {
          Vue.prototype.$shopItems = [...res.data.records].sort((a, b) => a.fields.Order - b.fields.Order).filter(i => !i.fields.Type); // just mine
          Vue.prototype.$externalShopItems = [...res.data.records].sort((a, b) => a.fields.Order - b.fields.Order).filter(i => i.fields.Type); // external
          resolved(res);
      })
    });
  }

  init_featured() {
    return new Promise((resolved) => {
      axios.post(`${source_link}/featured`).then((res) => {
        Vue.prototype.$featured_meets = res.data.records;
        resolved(res);
      })
    });
  }

  init_banner() {
    return new Promise((resolved) => {
      axios.post(`${source_link}/init_banner`).then((res) => {
        Vue.prototype.$banner = res.data.records.sort((a, b) => a.fields.Order - b.fields.Order);
        resolved(res);
      })
    });
  }

  init_ads() {
    return new Promise((resolved) => {
      axios.post(`${source_link}/init_ads`).then((res) => {
        Vue.prototype.$ads = res.data.records.sort((a, b) => a.fields.Order - b.fields.Order);
        resolved(res);
      })
    });
  }

  webpify() {
    // axios.post(`${source_link}/webpify`);
    axios.get(process.env.VUE_APP_url, this.config).then(async () => {
      var Airtable = require('airtable'); 
      const base = new Airtable({apiKey: process.env.VUE_APP_KEY}).base(process.env.VUE_APP_baseId);
      let records = await base('Table 1').select().all();
      Promise.all(records.map(async rec => {

      // console.log(rec)
  
          return new Promise((resolveWebp) => {
  
          // If the image is ALREADY a .webp, don't do anything!
          if (!rec.fields.Image || (rec.fields.Image && rec.fields.Image[0].filename.split(".")[1] === "webp")) {
            resolveWebp();
            return;
          }
  
          // process the image to webp, then
            srcToWebP(rec.fields.Image[0].url, {}).then((webpBlob) => {
              new Promise((resolve) => {
                var axios = require('axios');
                let formData = new FormData();
                formData.append( "image", webpBlob) // has to be named 'image'!
          
                // upload image to bb
                axios.post( 'https://api.imgbb.com/1/upload?key=84c270b8a531c47fb4ec646f3adaeb93', formData )
                .then( res => { 
                    resolve(res.data.data.display_url);
                } )
                // get the image URL from bb so you can upload that to Airtable
              }).then((imgUrl) => {
                // console.log(imgUrl, id) ;
                base('Table 1').update([
                  {
                    "id": rec.id,
                    "fields": {
  
                      "Image": [
                        {
                          "url": imgUrl
                        }
                      ]
                    }
                  }
                ])
                resolveWebp();
              });
            })
          })
        })
      ).then(() => {
        window.alert("done webpifying!");
        window.location.href = "./";
      })
    });
  }

  async init_airtable() {
    return new Promise((resolved) => {
      let now = performance.now();

      store.commit("setMaxMeetsLoaded", 0);
      store.commit("setMeetsLoadingStatus", false);

      axios.post(`${source_link}/init_airtable`).then((base) => {

        console.log("All meets loaded in " + Math.floor(performance.now() - now) / 1000 + " seconds.", store.state.meetsLoadingStatus);

        store.commit("setMaxMeetsLoaded", base.data.meetsCount);

        this.offsetMeets = base.data.offsetMeets;
        this.queryMeets();
        store.commit("setMeetsLoadingStatus", true);

        resolved();
      })
    })
  }

 
  queryMeets() {
    var meets = this.offsetMeets.map(i => {
      return {...i.fields, id: i.id};
    })
    .filter((i => { // Hide past ones by date
      if (i.Date) {
        return parseInt(i.Date.split("-").join("")) >= this.numericDate();
      }
    }))
    .sort((a, b) => { // Sort by date
      if (a.Date) {
        return parseInt(a.Date.split(":")[0].split("T")[0].split("-").join("")) - parseInt(b.Date.split(":")[0].split("T")[0].split("-").join(""));
      }
    });

    var counter = 0;

    for (let i = 0; i < meets.length; i++) {
      if (meets[i]['Date'])  {
        var month_int = meets[i]['Date'].split(":")[0].split("T")[0].split("-")[1];
        var day_int = meets[i]['Date'].split(":")[0].split("T")[0].split("-")[2];
        var year_int = meets[i]['Date'].split("-")[0];
      }

      if (!meets[i]['Lat'] && !meets[i]['Long'] && meets[i]['Address']) {
        this.process_latlng(meets[i].id, meets[i].Address).then((coords) => {

          // Sets the coordinates right after it logs it into Airtable
          meets[i]['Lat'] = coords.lat;
          meets[i]['Long'] = coords.long;

        });  
      } else if (meets[i]['Address']) {
        meets[i]['DistanceFromMe'] = this.distance({lat: parseFloat(meets[i]['Lat']), lng: parseFloat(meets[i]['Long'])});
      }

      if (day_int) 
        meets[i]['Day'] = day_int;

      if (year_int)
        meets[i]['Year'] = year_int;

      // English month and if Today or not
      if (month_int && store.state.lang !== "fr")  {
        meets[i]['Month'] = this.getMonth(month_int);

        var parsedDate = new Date().toString().split(" ").splice(0, 4).join(" "); //eg. "Thu Aug 11 2022"
       // If this meet is today, add an extra property to the array so you can highlight it in the DOM
        if (parsedDate.includes(`${meets[i]['Month'].substr(0, 3)} ${meets[i]['Day']} ${year_int}`)) {
          meets[i]['Today'] = true;
        } 
         // if today's date is equal to a car meet date minus 1
        if (`${meets[i]['Month'].substr(0, 3)} ${parseInt(meets[i]['Day'])}`
        === this.getNextMonthTomorrow(new Date().toString().split(" ").splice(1, 2).join(" "))) {
          meets[i]['Tomorrow'] = true;
        }

      }

      // French month and if Today or not
      else if (month_int && store.state.lang === "fr") {
        meets[i]['Month'] = this.getMonth_fr(month_int);

        var parsedDateFr =  new Date().toLocaleString('fr-FR', {month: "long", day: "2-digit", year: "numeric"}); //eg. "11 août 2022"
        // If this meet is today, add an extra property to the array so you can highlight it in the DOM
        if (parsedDateFr.normalize('NFD').replace(/\p{Diacritic}/gu, "") === `${meets[i]['Day']} ${meets[i]['Month']} ${year_int}`.toLowerCase()) {
          meets[i]['Today'] = true;
        } 
      }
        
      
      if (meets[i].Name) 
        meets[i]['url'] = `${meets[i].Name.toLowerCase().split(" ").join("-")}-${meets[i].Month.toLowerCase()}-${meets[i].Day.toLowerCase()}`;

      counter++;
    }
    
    if (counter === meets.length) {
      Vue.prototype.$meets = meets;
      store.commit("setMeets", meets);
      store.commit("setVisibleMeets", [...meets].slice(0,10));
      // resolved(meets);
    }
  }

  numericDate() {
    return parseInt(`${new Date().getFullYear()}${("0" + (new Date().getMonth() + 1)).slice(-2)}${("0" + new Date().getDate()).slice(-2)}`);
  }

  getMonth_fr(int) {
    switch(int) {
      case "01": {
        return "Janvier";
      }
      case "02": {
        return "Fevrier";
      }
      case "03": {
        return "Mars";
      }
      case "04": {
        return "Avril";
      }
      case "05": {
        return "Mai";
      }
      case "06": {
        return "Juin";
      }
      case "07": {
        return "Juillet";
      }
      case "08": {
        return "Aout";
      }
      case "09": {
        return "Septembre";
      }
      case "10": {
        return "Octobre";
      }
      case "11": {
        return "Novembre";
      }
      case "12": {
        return "Decembre";
      }
    }
  }

  getMonth(int) {
    switch(int) {
      case "01": {
        return "January";
      }
      case "02": {
        return "February";
      }
      case "03": {
        return "March";
      }
      case "04": {
        return "April";
      }
      case "05": {
        return "May";
      }
      case "06": {
        return "June";
      }
      case "07": {
        return "July";
      }
      case "08": {
        return "August";
      }
      case "09": {
        return "September";
      }
      case "10": {
        return "October";
      }
      case "11": {
        return "November";
      }
      case "12": {
        return "December";
      }
    }
  }
  getNextMonthTomorrow(date) {
    switch(date) {
      case "Jan 31": {
        return "Feb 1";
      }
      case "Feb 28": {
        return "Mar 1";
      }
      case "Mar 31": {
        return "Apr 1";
      }
      case "Apr 30": {
        return "May 1";
      }
      case "May 31": {
        return "June 1";
      }
      case "June 30": {
        return "July 1";
      }
      case "July 31": {
        return "Aug 1";
      }
      case "Aug 31": {
        return "Sep 1";
      }
      case "Sep 30": {
        return "Oct 1";
      }
      case "Oct 31": {
        return "Nov 1";
      }
      case "Nov 30": {
        return "Dec 1";
      }
      case "Dec 31": {
        return "Jan 1";
      }
      default: {
        return date.split(" ")[0] + " " + (parseInt(date.split(" ")[1]) + 1);
      }
    }
  }
}
