import Vue from "vue";
import VueRouter from "vue-router";

// Because Vue is not global we tie Vue
// and VueRouter together manually
Vue.use(VueRouter);

// Initialize router
const router = new VueRouter({
  base: "/",
  // mode: "hash", // Enable for APP
  mode: "history", // Enable for WEBSITE
  routes: [
    {
      path: "/",
      component: () => import("./components/MeetsList.vue"),
    },
    // {
    //   path: "/webpify",
    //   component: () => import("./components/WebPify.vue"),
    // },
    {
      path: "/map",
      component: () => import("./components/MapPage.vue"),
    },
    {
      path: "/checkout",
      component: () => import("./components/CheckoutPage.vue"),
    },
    {
      path: "/privacy-policy",
      component: () => import("./components/PrivacyPolicy.vue"),
    },
    {
      path: "/terms-of-service",
      component: () => import("./components/TermsOfService.vue"),
    },
    {
      path: "/shop",
      component: () => import("./components/ShopPage.vue"),
    },
    {
      path: "/product",
      component: () => import("./components/ShopPage.vue"),
    },
    {
      path: "/product/:slug",
      component: () => import("./components/ProductPage.vue"),
    },
    {
      path: "/calendar",
      component: () => import("./components/CalendarPage.vue"),
    },
    {
      path: "/calendrier",
      component: () => import("./components/CalendarPage.vue"),
    },
    {
      path: "/:slug",
      component: () => import("./components/MeetsPage.vue"),
    },
    {
      path: "/?:slug",
      component: () => import("./components/MeetsPage.vue"),
    },
    {
      path: '/:pathMatch(.*)*',
      component: () => import("./components/MeetsPage.vue"),
    },
  ],
});

export default router;
