import Vue from 'vue'
import App from './App.vue'
import airtable from './airtable';
import geo from './geo';
import store from './store';
import router from './router';
import VueHead from 'vue-head';
Vue.config.productionTip = false;
Vue.use(VueHead);

let mobileBrowsers = 
  [
  'Safari', 
  'Chrome', 
  'Firefox', 
  'Edge',
  'Yandex.Browser', 
  'Samsung Browser', 
  'Opera Mobile', 
  'MIUI Browser', 
  'Huawei Browser', 
  'Phoenix', 
  'UC Browser',
  'VivoBrowser',
  'HeyTap Browser',
  'Amazon Silk',
];

Vue.prototype.$isBrowser = mobileBrowsers.some(((val) => {
  return window.navigator.appVersion.includes(val);
}))

Vue.prototype.$handleURL = ((url) => {
  if (!Vue.prototype.$isBrowser) return `javascript: window.open('${url}', '_blank')`;
  return url;
});

// store.commit("setServerAddress",  window.location.protocol + "//" + window.location.hostname + ":" + 8081);
store.commit("setServerAddress", "https://carllective-api.vercel.app");

if (window.location.href.includes("fr.")) {
  store.commit("setLang", "fr");
} else {
  store.commit("setLang", "en");
}
// store.commit("setLang", "fr");


function initVue() {
  return new Promise((res) => {
      new Vue({
        store,
        router,
        render: h => h(App),
      }).$mount('#app');
      res();
    })
}


Vue.prototype.$geoAPI = geo;

// setTimeout(() => {
  Promise.all([
  
    airtable.init_shop(),
    airtable.init_shop_items(),
    airtable.init_featured(),
    airtable.init_banner(),
    airtable.init_ads()
    
  ])
.then(() => {
  airtable.init_airtable2();
  airtable.init_airtable();
  getLocation();
  initVue();
  
});


function getLocation() {
  if (Vue.prototype.$isBrowser) {
    geo.fetchLocation2();
  }
  else {
    // Special function for App only
    document.addEventListener("deviceready", () => {geo.fetchLocation2()}, false);
  }
  
}

// }, 10000)
// store.commit("setMeets", null);

// airtable.init_all_of_airtable();

  
  



