import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    your_location: null,
    your_ip: null,
    loading: true,
    lang: "en",
    activeFilter: "Date",
    activeProvince: {en: 'Canada-Wide', fr: 'Canada-Wide'},
    scrollY: 0,
    meetListScrollY: 0,
    meetsList:[],
    meetsLoadingStatus:false,
    meetsLoadedIndex: 0,
    maxMeetsLoaded: 0,
    meetGroupsLoadedIndex: 0,
    visibleMeets: [],
    filteredMeets: [],
    readable_location: '',
    serverAddress: "https://carllective-api.vercel.app",
    // serverAddress: window.location.protocol + "//" + window.location.hostname + ":" + 8081
    cart: null,
  },

  mutations: {
    setYourLocation(state, your_location) {
      Vue.set(state, "your_location", your_location);
    },
    setReadableLocation(state, readable_location) {
      Vue.set(state, "readable_location", readable_location);
    },
    setMaxMeetsLoaded(state, maxMeetsLoaded) {
      Vue.set(state, "maxMeetsLoaded", maxMeetsLoaded);
    },
    setMeetsLoadedIndex(state, meetsLoadedIndex) {
      Vue.set(state, "meetsLoadedIndex", meetsLoadedIndex);
    },
    setMeetGroupsLoadedIndex(state, meetGroupsLoadedIndex) {
      Vue.set(state, "meetGroupsLoadedIndex", meetGroupsLoadedIndex);
    },
    setMeetsLoadingStatus(state, meetsLoadingStatus) {
      Vue.set(state, "meetsLoadingStatus", meetsLoadingStatus);
    },
    setYourIp(state, your_ip) {
      Vue.set(state, "your_ip", your_ip);
    },
    setLoading(state, loading) {
      Vue.set(state, "loading", loading);
    },
    setLang(state, lang) {
      Vue.set(state, "lang", lang);
    },
    setActiveFilter(state, activeFilter) {
      Vue.set(state, "activeFilter", activeFilter);
    },
    setActiveProvince(state, activeProvince) {
      Vue.set(state, "activeProvince", activeProvince);
    },
    setScrollY(state, scrollY) {
      Vue.set(state, "scrollY", scrollY);
    },
    setMeetListScrollY(state, meetListScrollY) {
      Vue.set(state, "meetListScrollY", meetListScrollY);
    },
    setMeets(state, meetsList) {
      Vue.set(state, "meetsList", meetsList);
    },
    setFilteredMeets(state, filteredMeets) {
      Vue.set(state, "filteredMeets", filteredMeets);
    },
    setVisibleMeets(state, visibleMeets) {
      Vue.set(state, "visibleMeets", visibleMeets);
    },
    setServerAddress(state, serverAddress) {
      Vue.set(state, "serverAddress", serverAddress);
    }
  },

  actions: {
    setYourIp(context, your_ip) {
      context.commit("setYourIp", your_ip);
    },
    setLoading(context, loading) {
      context.commit("setLoading", loading);
    },
    setLang(context, lang) {
      context.commit("setLang", lang);
    },
    setActiveProvince(context, activeProvince) {
      context.commit("setActiveProvince", activeProvince);
    },
  },
  plugins: [createPersistedState({
    storage: window.sessionStorage
  })],
});
