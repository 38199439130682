/* eslint-disable no-unused-vars */
var axios = require('axios');
// import Vue from "vue";
import store from "./store.js";
import { Geolocation } from '@capacitor/geolocation';

/*eslint-disable  */


export default new class Geo {
  constructor() {    
  }

   init() {
    return new Promise((res) => {
     
      // Fetch your IP (Free unlimited API)
      axios.get("https://api.bigdatacloud.net/data/client-ip").then((ip) => {
        return ip.data.ipString;
      }).then((fetched_ip) => {

        var check_if_location_data_is_valid = [];
        if (store.state.your_location) {
            check_if_location_data_is_valid = [
            typeof store.state.your_location.city === 'string',
            typeof store.state.your_location.state_code === 'string',
            typeof store.state.your_location.country === 'string',
          ]
        }

        // If cookie hasn't stored your location (ie. first time logging in),
        // OR your IP address changes, 
        // OR the location data is gibbberish (can happen if I push changes and user hasn't refreshed cookies),
        // then request API call to fetch your location
        // I based this off of IP change because it's safe to say if your IP changes, there's possibility you changed location. Reduce API calls
        if (!store.state.your_location || fetched_ip !== store.state.your_ip || check_if_location_data_is_valid.indexOf(false) > -1) {

          console.log(fetched_ip)
          // Store your IP
          store.commit("setYourIp", fetched_ip);

          // Fetch your Latitude and Longitude and Location
          this.fetchLocation2().then((location) => {
            res(location);
            window.location.reload();
          });
          
          }
          else {
            res(store.state.your_location);
          }
       })
      })
    }

  async fetchLocation2() {
    let geo;

    try {
      geo  = await Geolocation.getCurrentPosition();
    } catch {
      geo = null;
    }
    
    // console.log("doing geo")
    return new Promise(resolve => {

      if (!geo) resolve();
      
        axios.get(`https://nominatim.openstreetmap.org/reverse?lat=${geo.coords.latitude}&lon=${geo.coords.longitude}&format=json`).then((res) => {
          store.commit("setYourLocation", {
            latitude: geo.coords.latitude,
            longitude: geo.coords.longitude,
            town: res.data.address.town || res.data.address.city || res.data.address.village,
            province: res.data.address.state
          });
          // console.log(res.data.address.state)
          store.commit("setActiveProvince", {en: res.data.address.state, fr: res.data.address.state});

          store.commit("setReadableLocation", `${res.data.address.town || res.data.address.city || res.data.address.village}, ${this.shortformState(res.data.address.state)}`)
        }).then(() => {resolve();});
      })
    }
    
    nearest(params) {
      
     return new Promise((res) => {
      if (store.state.your_location) {
        const options = {
          method: 'GET',
          url: `https://wft-geo-db.p.rapidapi.com/v1/geo/locations/${store.state.your_location.lat}${store.state.your_location.lon}/nearbyCities`,
          params: params,
          headers: {
            'X-RapidAPI-Key': '6d50373928msh8ea7650abbc2053p1c1465jsn8d11a1823388',
            'X-RapidAPI-Host': 'wft-geo-db.p.rapidapi.com'
          }
        };
        axios.request(options).then(function (response) {
          res(response.data)
        }).catch(function (error) {
          console.log("Please set your location", error);
        });
      } else {
        res();
      }
     })
    }

    shortformState(state) {
      console
      let prov = 
        {
        "Alberta": "AB", 
        "British Columbia": "BC",
        "Manitoba": "MB",
        "New Brunswick": "NB",
        "Newfoundland and Labrador": "NL",
        "Northwest Territories": "NT",
        "Nova Scotia": "NS",
        "Nunavut": "NU",
        "Ontario": "ON",
        "Prince Edward Island": "PE",
        "Quebec": "QB",
        "Saskatchewan": "SK",
        "Yukon": "YK"
        }
      
      return prov[state];

    }

}
